<template>
    <div id="account">
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.merchantId" placeholder="请选择商户" @change="onMerchantSel" clearable
                    class="mr20">
                    <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
                        :value="item.merchantId">
                    </el-option>
                </el-select>
                <el-select v-model="tableParams.shopId" placeholder="请选择店铺" clearable @change="onShopSel" class="mr20">
                    <el-option v-for="item in tableShopList" :key="item.shopId" :label="item.shopName"
                        :value="item.shopId">
                    </el-option>
                </el-select>
                <el-select v-model="tableParams.serviceId" placeholder="请选择服务内容" clearable @change="onServiceSel"
                    class="mr20">
                    <el-option v-for="item in tableServiceList" :key="item.serviceId" :label="item.serviceName"
                        :value="item.serviceId">
                    </el-option>
                </el-select>
            </div>
            <div class="search-R">
                <el-button type="primary" @click="onAdd()">新增工位区域</el-button>
            </div>
        </section>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe :header-cell-style="{
                    background: '#FAFAFA', color: '#000000'
                }">
            <el-table-column label="区域名称" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.planName || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="3D俯视图" align="center">
                <template slot-scope="scope">
                    <el-image style="width: .28rem; height: .28rem" :src="scope.row.fileUrl"
                        :preview-src-list="[scope.row.fileUrl]">
                    </el-image>
                </template>
            </el-table-column>

            <el-table-column label="归属服务内容" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.serviceName || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
                    <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%"
            custom-class="add-dialog">
            <el-form :model="formData" :rules="rules" ref="form-ref" label-width="120px">
                <!-- 区域名称 -->
                <el-form-item label="区域名称" prop="planName">
                    <el-input v-model.trim="formData.planName" autocomplete="off" placeholder="请填写区域名称"></el-input>
                </el-form-item>
                <!-- 归属服务内容 -->
                <el-form-item label="归属服务内容" prop="serviceId">
                    <el-select v-model="formData.serviceId" placeholder="请选择归属服务内容">
                        <el-option v-for="item in tableServiceList" :key="item.serviceId" :label="item.serviceName"
                            :value="item.serviceId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="3D俯视图" prop="fileId">
                    <div class="upload-btn" @click="onUpload(1, 1)">
                        <img v-if="formData.fileUrl" :src="formData.fileUrl" class="upload-img">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </div>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
            </div>
        </el-dialog>
        <!-- 图片上传组件 -->
        <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="fileType"></UpLoad>
    </div>
</template>

<script>
import {
    addArea, delArea, getAreaInfo, getAreaList, editArea
} from "@/api/commercial/desk";
import {
    getAutoCommercialList,
} from "@/api/commercial/commercial"
import {
    getIdShopList,
} from "@/api/commercial/shop"
import {
    getIdServiceList,
} from "@/api/commercial/service"
import UpLoad from "@/components/upload";
import {
    getFileList
} from "@/api/common";
import {
    ObjToOpt,
} from "@/utils/utils"
import { rules } from "@/db/rules";
export default {
    components: { UpLoad },
    computed: {

        maxlimit() {
            if (this.fileList && this.fileList.length > 0) {
                return 5 - this.fileList.length
            } else {
                return 5
            }
        },
        countTitle() {
            let obj = {
                1: "进货数量",
                2: "销货数量",
                3: "损耗数量"
            }
            return obj[this.inventoryParams.inventoryType]
        }
    },

    data() {
        return {
            rules,

            ObjToOpt,

            commercialList: [],// 商户列表
            tableShopList: [],//店铺列表
            tableServiceList: [],//服务列表

            // table列表
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],
            tableParams: {
                page: 1,
                size: 10,
                merchantId: "", //商户ID
                shopId: "", //店铺ID
                serviceId: "", //服务ID
            },
            // 表单
            editShow: false, //弹框开关
            editTitle: "", //弹框姓名
            formData: {
                planId: '',
                planName: "", //q区域姓名
                fileId: "", //3d视图
                serviceId: "", //服务id
                merchantId: "", //商户id
                shopId: "", //店铺id
            },
            fileList: [],//图片列表
            fileType: 1,
            upType: "",
            imgUrl: '',


        };
    },

    mounted() {
        this.getAreaList();
        this.getAutoCommercialList();// 【请求】商户下拉列表
    },
    methods: {
        // 【请求】商户下拉列表
        getAutoCommercialList() {
            getAutoCommercialList().then((res) => {
                if (res.isSuccess == "yes") {
                    this.commercialList = res.data;
                    if (res.data && res.data.length > 0) {
                        this.tableParams.merchantId = res.data[0].merchantId;
                        this.getTableIdShopList();
                    }
                }
            });
        },
        // 【请求】筛选商户店铺列表
        getTableIdShopList() {
            let data = {
                merchantId: this.tableParams.merchantId
            };
            getIdShopList(data).then(res => {
                if (res.isSuccess == "yes") {
                    this.tableShopList = res.data;
                    this.tableParams.shopId = res.data[0].shopId;
                    this.getAreaList();// 【请求】列表
                    this.getTableIdServiceList();
                }
            })
        },

        // 【请求】筛选服务内容列表
        getTableIdServiceList() {
            let data = {
                shopId: this.tableParams.shopId,
                serviceTemplateType:5
            };
            getIdServiceList(data).then(res => {
                if (res.isSuccess == "yes") {
                    this.tableServiceList = res.data;
                }
            })
        },

        // 【监听】筛选商户
        onMerchantSel() {
            this.tableParams.shopId = "";
            this.tableParams.serviceId = "";
            this.reload();
            if (this.tableParams.merchantId) {
                this.getTableIdShopList();
            } else {
                this.tableShopList = [];
            }
        },

        // 【监听】筛选店铺
        onShopSel() {
            this.$forceUpdate();
            this.tableParams.serviceId = "";
            this.reload();
            if (this.tableParams.shopId) {
                this.getTableIdServiceList();
            } else {
                this.tableServiceList = [];
            }
        },

        // 【监听】服务筛选
        onServiceSel() {
            this.$forceUpdate();
            this.reload();
        },



        // 【请求】区域列表
        getAreaList() {

            getAreaList(this.tableParams).then((res) => {
                if(res.data){
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }else{
                    this.tableData = []
                    this.total = 0;
                }
            });
        },

        // 【请求】新增工位区域
        addArea() {
            addArea(this.formData).then(res => {
                if (res.isSuccess == "yes") {
                    this.editShow = false;
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.reload()
                }
            })
        },
         // 【请求】编辑工位区域
         editArea() {
            editArea(this.formData).then(res => {
                if (res.isSuccess == "yes") {
                    this.editShow = false;
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.reload()
                }
            })
        },

        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getAreaList();
        },

        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getAreaList();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.size = size;
            this.reload();
        },

        // 【监听】新增工位区域
        onAdd() {
            this.editShow = true;
            this.editTitle = "新增工位区域";
            this.formData = {
                planId: '',
                planName: "", //q区域姓名
                fileId: "", //3d视图
                serviceId: "", //服务id
                merchantId: "", //商户id
                shopId: "", //店铺id
            }
            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },

        // 【监听】编辑按钮
        onEdit(row) {
            console.log('row: ', row);
            this.editShow = true;
            this.editTitle = "编辑工位区域";
            this.formData = {
                planId:row.planId,
                planName:row.planName, //q区域姓名
                fileId:row.fileId, //3d视图
                fileUrl:row.fileUrl,
                serviceId:row.serviceId, //服务id
                merchantId:row.merchantId, //商户id
                shopId:row.shopId, //店铺id
            }
            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },

        // 【监听】表单提交
        onSubmit(formName) {
            this.formData.merchantId = this.tableParams.merchantId;
            this.formData.shopId = this.tableParams.shopId;
            this.$refs[formName].validate((valid) => {

                if (valid) {
                    if (this.formData.planId) {
                        this.editArea();
                    } else {
                        this.addArea();
                    }

                } else {
                    return false;
                }
            });
        },

        // 【监听删除】
        onDel(row) {

            this.$confirm('你确定要删除该工位区域吗?', '删除工位区域', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                delArea({ planId: row.planId }).then(res => {
                    if (res.isSuccess == "yes") {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getAreaList();
                    }
                })
            });
        },
        // 【监听】文件上传
        onUpload(fileType, upType) {
            this.fileType = fileType;
            this.upType = upType;
            this.$refs.up.onUpload();
        },
        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            let data = {
                fileIds
            }
            getFileList(data).then(res => {
                if (res.isSuccess == "yes") {
                    // 缩略图
                    if (this.upType == 1) {
                        this.formData.fileId = fileIds;
                        this.formData.fileUrl = res.data[0].url;
                        this.imgUrl = res.data[0].url;

                    }
                    // 主图
                    else {
                        this.formData.fileIdList = fileIds;
                        this.fileList = [...this.fileList, ...res.data]
                    }

                    this.$forceUpdate();
                }
            })
        },
        onImgDel(item) {
            this.$confirm('你确定要删除吗?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.fileList.splice(this.fileList.indexOf(item), 1);
            });
        },



    },
};
</script>

<style lang="scss" scoped>
#account {
    padding: .15rem;
    box-sizing: border-box;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.upload-btn {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.upload-btn:hover {
    border-color: #409EFF;
}

.uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
}

.upload-img {
    display: inline-block;
    border: 1px dashed #d9d9d9;
    margin: 0 .1rem .1rem 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    position: relative;

    >img {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

.tip {
    color: red;
    display: flex;
}

.imgs {
    display: flex;
    flex-wrap: wrap;
}

.img-del {
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    visibility: hidden;

    >.el-icon-delete {
        font-size: 28px;
        color: #fff;
    }
}

.upload-img:hover .img-del {
    visibility: visible;
}
</style>